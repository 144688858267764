import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ControlledInput } from '../input/input';
import { PartialDeep } from 'type-fest';
import formValidationRules from '../../utils/formValidationRules';
import Routes from '../../utils/routes';
import AppLink from '../app-link/appLink';
import { Alert, AlertIcon, Box, Button, Flex, FlexProps } from '@chakra-ui/react';
import { AiOutlineGoogle } from 'react-icons/ai';
import { SiMicrosoft } from 'react-icons/si';
import { BsMicrosoft } from 'react-icons/bs';
import { FcGoogle } from 'react-icons/fc';
import { Icon } from '@chakra-ui/icons';
import Card from '../card/card';
import { v } from '@fullcalendar/core/internal-common';

export type SignUpFormModel = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
};

const defaultValues: PartialDeep<SignUpFormModel> = {
  email: '',
  password: '',
  firstName: '',
  lastName: '',
};

type Props = {
  disabled?: boolean;
  loading?: boolean;
  error?: string;
  onSignUpUsingGoogle: () => void;
  onSignUpUsingMicrosoft: () => void;
  onSubmit: (data: SignUpFormModel) => void;
} & Omit<FlexProps, 'onSubmit'>;

const { required, passwordMin6Characters } = formValidationRules;

const SignUpForm = ({ disabled, loading, onSubmit, error, onSignUpUsingGoogle, onSignUpUsingMicrosoft, ...rest }: Props) => {
  const [loadingState, setLoadingState] = useState({
    google: false,
    microsoft: false,
  });

  const handleMicrosoftSignUp = async () => {
    setLoadingState({ ...loadingState, microsoft: true });
    try {
      await onSignUpUsingMicrosoft();
    } finally {
      //setLoadingState({ ...loadingState, microsoft: false });
    }
  };

  const handleGoogleSignUp = async () => {
    setLoadingState({ ...loadingState, google: true });
    try {
      await onSignUpUsingGoogle();
    } finally {
      //setLoadingState({ ...loadingState, google: false });
    }
  };

  const formMethods = useForm<SignUpFormModel>({ defaultValues });
  const { handleSubmit, control } = formMethods;

  return (
    <Flex alignItems="center" minH="100%" justifyContent="center">
      <Card>
        <Flex flexDirection="column" maxW="container.md" w="100%" gap={2}>
          <div>
            <Box as="h2" fontSize="xl" textAlign="center" fontWeight="normal">
            New user? Sign up here

            </Box>
          </div>
          {error && (
            <Alert status="error">
              <AlertIcon />
              {error}
            </Alert>
          )}



<Flex direction="column" p="4"  justifyContent="center" alignItems="center"   gap="6">
        <Button
          width="50%"
          bgGradient="linear(to-r, white, gray.50)"
          boxShadow="xl"
          _hover={{ borderColor: 'gray.400', boxShadow: '2xl', transform: 'scale(1.05)' }}
          transition="all 0.3s ease-in-out"
          colorScheme="gray.400"
          // loadingText="Google"
          onClick={handleGoogleSignUp}
        isDisabled={disabled || loadingState.google}
        isLoading={loadingState.google}
          leftIcon={<Icon as={FcGoogle} w={8} h={8} />}
          size="lg"
          variant="outline"
          // border="2px"
        >
          Google
        </Button>
        <Button
          width="50%"
          bgGradient="linear(to-r, white, gray.50)"
          boxShadow="xl"
          _hover={{ borderColor: 'gray.400', boxShadow: '2xl', transform: 'scale(1.05)' }}
          transition="all 0.3s ease-in-out"
          colorScheme="gray.400"
          // loadingText="Microsoft"
          onClick={handleMicrosoftSignUp}
        isDisabled={disabled || loadingState.microsoft}
        isLoading={loadingState.microsoft}
          leftIcon={<Icon as={BsMicrosoft} color="#2f2f9d" w={8} h={8} />}
          size="lg"
          variant="outline"
        >
          Microsoft
        </Button>
        </Flex>


          {/* <Button
            w="100%"
            colorScheme={'red'}
            size="lg"
            backgroundColor="#DB4437"
            onClick={handleGoogleSignUp}
            isDisabled={disabled || loadingState.google}
            isLoading={loadingState.google}
          >
            <Icon as={AiOutlineGoogle} boxSize={6} mr={2} />
            Sign up using Google
          </Button>
          <Box h={2} />
          <Button
            w="100%"
            colorScheme={'blue'}
            size="lg"
            backgroundColor="#00A4EF"
            color="white"
            onClick={handleMicrosoftSignUp}
            isDisabled={disabled || loadingState.microsoft}
            isLoading={loadingState.microsoft}
          >
            <Icon as={SiMicrosoft} boxSize={6} mr={2} />
            Sign up using Microsoft
          </Button> */}
          
          {/* <Box textAlign="center" fontSize="lg">
            or using email and password
          </Box> */}
          {window.location.href.includes('status=veerendra') && (
          <FormProvider {...formMethods}>
            <Flex as="form" flexDirection="column" gap={6} {...rest} onSubmit={handleSubmit(onSubmit)}>
              <Flex flexDirection="column" gap={2}>
                <ControlledInput name="email" control={control} placeholder="Email" type="email" rules={{ required }} />
                <ControlledInput
                  name="password"
                  control={control}
                  placeholder="Password"
                  type="password"
                  rules={{ required, minLength: passwordMin6Characters }}
                />
                <ControlledInput name="firstName" control={control} placeholder="First name" />
                <ControlledInput name="lastName" control={control} placeholder="Last name" />
              </Flex>
              <Button type="submit" size="lg" colorScheme="blue" w="100%" isDisabled={disabled} isLoading={loading}>
                Submit
              </Button>
            </Flex>
          </FormProvider> 
          )}
          <Box h={4} />
          <Flex alignItems="center" justifyContent="center" fontSize="lg">
            <AppLink to={Routes.LOGIN}>Back to Sign in</AppLink>
          </Flex>
        </Flex>
      </Card>
    </Flex>
  );
};

export default SignUpForm;
